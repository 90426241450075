<template>
  <div>
    <OrderForm
      :supplier-from-voice="supplierFromVoice"
    > </OrderForm>
  </div>
</template>

<script>
import OrderForm from "../OrderForm.vue";

export default {
  name: "OrderNewFormTab",
  components: {
    OrderForm,
  },
  props: {
    supplierFromVoice: {
      type: String,
      default: undefined
    },
  }
};
</script>

<style>
</style>