<template>
  <div>
    <v-form v-model="formValid">
      <v-subheader class="font-italic font-weight-light py-1"
        >Dati Generali</v-subheader
      >
      <v-row no-gutters>
        <v-col cols="2">
          Codice Ordine
          <v-text-field
            v-model="codeOrder"
            filled
            disabled
            label="Codice Ordine"
            prepend-icon="mdi-code-tags"
            hide-details="auto"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="ml-5">
          Descrizione Ordine
          <v-text-field
            v-model="descOrder"
            :placeholder="descPlaceHolder"
            filled
            :rules="[presenceRule]"
            label="Descrizione Ordine"
            prepend-icon="mdi-clipboard-text"
            hide-details="auto"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <DateTimePicker
            class="ma-5"
            v-model="expectedDeliveryDate"
            dense
            filled
            label="Data di consegna prevista"
            :show-time="false"
            date-picker-width="300px"
            :min="(() => {
              let today = new Date()
              return new Date(today.setDate(today.getDate() + 1))
            })()"
          ></DateTimePicker>
        </v-col>
        <v-col class="ml-5" cols="3">
          Note
          <v-textarea
            v-model="reason"
            label="Note"
            filled
            rounded
            hide-details="auto"
            prepend-icon="mdi-comment"
            rows="2"
            row-height="15"
            :rules="[max255CharRule]"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <v-subheader class="font-italic font-weight-light"
      >Dettaglio ordine</v-subheader
    >
    <div
      class="mt-10 border-default"
      style="
        min-height: 45vh;
        max-height: 45vh;
        height: 45vh;
        overflow-x: hidden;
      "
      ref="dataTableWrapper"
    >
      <v-row>
        <v-spacer></v-spacer>
        <div
          style="cursor: pointer"
          @click="openGenericElementDialog"
          text
          class="mt-3 mr-5 border-default"
        >
          <v-icon class="mr-2" medium>mdi-text-box-plus-outline</v-icon>

          Aggiungi prodotto non a magazzino
        </div>
      </v-row>
      <ItemDataTable
        no-data-text="Aggiungere elemento ad ordine"
        :single-select="false"
        v-model="selectedItems"
        @input="handleSelection"
        class="mt-10"
        :loading="loading"
        item-key="id"
        :items="itemsAvailable"
        :headers="headerItems"
        :select-on-row-click="true"
        :show-actions="true"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
        :translator="translator"
      >
        <template v-slot:custom-pricePurchase="{ item }">
          <v-chip color="primary">
            € {{ item["pricePurchase"] ? item["pricePurchase"] : 0 }}</v-chip
          >
        </template>
        <template v-slot:custom-discountedPrice="{ item }">
          <v-chip color="primary">
            €
            {{
              item["pricePurchase"] && item["discount"]
                ? Number(
                    Number(item["pricePurchase"]) *
                      (1 - Number(item["discount"]) / 100)
                  ).toFixed(2)
                : Number(item["pricePurchase"]).toFixed(2)
            }}</v-chip
          >
        </template>
        <template v-slot:custom-discount="{ item }">
          <v-text-field
            class="border-default mt-3 mb-3"
            type="number"
            min="0"
            max="100"
            dense
            :rules="[]"
            @click.stop=""
            @input="($e) => handleDiscountInput($e, item)"
            filled
            flat
            solo
            hide-details="auto"
            not-specified-text="0"
            v-model="item['discount']"
          >
          </v-text-field>
        </template>
        <template v-slot:custom-priceTot="{ item }">
          <v-chip color="primary">
            €
            {{
              item["pricePurchase"] && item["discount"] && item["qtyTidying"]
                ? Number(
                    Number(item["pricePurchase"]) *
                      (1 - Number(item["discount"]) / 100) *
                      item["qtyTidying"]
                  ).toFixed(2)
                : Number(item["pricePurchase"] * item["qtyTidying"]).toFixed(2)
            }}</v-chip
          >
        </template>
        <template v-slot:custom-qtyTidying="{ item }">
          <v-text-field
            class="border-default mt-3 mb-3"
            type="number"
            min="0"
            max="100"
            dense
            @click.stop=""
            :rules="[qtyCheck]"
            filled
            flat
            solo
            hide-details="auto"
            not-specified-text="0"
            v-model="item['qtyTidying']"
          >
          </v-text-field>
        </template>
        <template v-slot:actions="{ item }">
          <v-btn @click.stop="" icon @click="handleRemoveGenericElement(item)"
            ><v-icon> mdi-trash-can </v-icon></v-btn
          >
        </template>
      </ItemDataTable>
    </div>

    <v-row>
      <v-spacer></v-spacer>
      <OrderSummary :selected-items="selectedItems"></OrderSummary>
    </v-row>

    <v-divider class="mt-5 mb-5"></v-divider>

    <GenericElementListDialog
      :open-dialog="openGenericElementListDialog"
      @close="openGenericElementListDialog = false"
      @add-element="handleAddGenericElement"
      :hide-tags-infos="true"
    ></GenericElementListDialog>
  </div>
</template>

<script>
import Vue from "vue";
import ItemDataTable from "@/components/common/TypeDataTable.vue";
import currentUser from "@/services/currentUser.service";
import ordersService from "@/services/warehouse/orders.service.js";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import OrderSummary from "@/components/areas/warehouse/orders/components/OrderSummary.vue";
import GenericElementListDialog from "@/components/common/GenericElementListDialog.vue";
import DateTimePicker from '@/components/common/DateTimePicker.vue'

export default {
  components: {
    ItemDataTable,
    GenericElementListDialog,
    OrderSummary,
    DateTimePicker
  },
  data: function () {
    return {
      codeOrder: "",
      codePlaceHolder: "ES...OR01",
      descOrder: "Ordine",
      descPlaceHolder: "ES...Prodotti Salone, Prodotti Bar",
      loading: false,
      formValid: false,
      itemsAvailable: [],
      selectedItems: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 100,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      reason: undefined,
      openGenericElementListDialog: false,
      expectedDeliveryDate: undefined,
      headerItems: [
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Prezzo listino d'acquisto",
          value: "pricePurchase",
          type: "custom",
        },

        {
          text: "% di Sconto",
          value: "discount",
          type: "custom",
          width: "150px"
        },
        {
          text: "Prezzo unitario scontato",
          value: "discountedPrice",
          type: "custom",
        },
        {
          text: "N° pezzi da ordinare",
          value: "qtyTidying",
          type: "custom",
          width: "150px"
        },
        {
          text: "Totale Scontato",
          value: "priceTot",
          type: "custom",
        },
      ],
      qtyCheck: (qty) => {
        if (qty <= 0) {
          this.isValid();
          return itemVocabulary.Correct_Qty.desc;
        } else {
          this.isValid();
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      max8CharRule: (v) => {
        if (v) {
          return v.length <= 8 || "Al massimo 8 caratteri";
        } else {
          return true;
        }
      },
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
    };
  },
  mounted() {
    this.setValidValue(false);
    this.lastCode();
    this.$store.dispatch("warehouse/orders/resetFields");
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);
    this.setUser();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    handleRemoveGenericElement(item) {
      this.itemsAvailable = this.itemsAvailable.filter(
        (i) => i.description !== item.description
      );
    },
    handleAddGenericElement(genericElement) {
      let idx = this.itemsAvailable.length;
      this.itemsAvailable.push({
        id: idx,
        description: genericElement.description,
        pricePurchase: genericElement.price,
        discount: genericElement.discount,
        qtyTidying: genericElement.quantity,
      });
    },
    lastCode() {
      ordersService.getLastCode().then((lastOrder) => {
        let progressiveInCode;
        let today = new Date();
        if (
          !!lastOrder &&
          Number(lastOrder.startDate.substring(0, 4)) == today.getFullYear()
        ) {
          progressiveInCode = (Number(lastOrder.progressive) + 1).toString();
          while (progressiveInCode.length < 5) {
            progressiveInCode = "0" + progressiveInCode;
          }
        } else {
          progressiveInCode = "00001";
        }
        this.codeOrder = "ORD" + today.getFullYear() + "-" + progressiveInCode;
        this.updateField("code", this.codeOrder);
      });
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    handleSelection(itemsSelected) {
      this.selectedItems = itemsSelected;
    },
    handleDiscountInput(event, item) {
      setTimeout(() => {
        if(Number(event) <= 100) {
          item.discount = event
        } else {
          item.discount = 100
        }
      }, 50);
    },
    setUser() {
      let userId = 0;
      currentUser.getUser().then((user) => {
        userId = user.id;
        this.updateField("userId", userId);
      });
    },
    updateField(nameField, value) {
      this.$store.dispatch("warehouse/orders/updateField", {
        field: nameField,
        value: value,
      });
    },
    setValidValue(value) {
      this.$emit("update:valid", value);
      if (this.bus) {
        this.bus.$emit("update:valid", value);
      }
      this.$store.dispatch("warehouse/orders/setValidState", value);
      this.formValid = value;
    },
    isValid() {
      if (
        this.selectedItemsCheck &&
        this.codeOrder !== "" &&
        this.descOrder !== ""
      ) {
        this.setValidValue(true);
      } else {
        this.setValidValue(false);
      }
    },
    resetFields() {
      this.$store.dispatch("warehouse/orders/resetFields");
      this.selectedItems = [];
      this.itemsAvailable = [];
      this.descOrder = "";
      this.reason = "";
      this.lastCode();
    },
    openGenericElementDialog() {
      this.openGenericElementListDialog = true;
    },
  },
  props: {
    fromSubStock: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  computed: {
    selectedItemsCheck() {
      let checkItems = [];
      this.selectedItems.map((item) => {
        if (item.qtyTidying && item.qtyTidying > 0) {
          checkItems.push(item);
        }
      });

      this.updateField("currentItemsSelected", checkItems);
      if (
        checkItems.length == this.selectedItems.length &&
        checkItems.length > 0
      )
        return true;
      else return false;
    },
  },
  watch: {
    selectedItems(newVal) {
      this.selectedItems = newVal;
      this.isValid();
    },
    codeOrder(newVal) {
      this.updateField("code", newVal);
      this.isValid();
    },
    descOrder(newVal) {
      this.updateField("description", newVal);
      this.isValid();
    },
    reason(newVal) {
      this.updateField("reason", newVal);
      this.isValid();
    },
    expectedDeliveryDate(newVal) {
      this.updateField("expectedDeliveryDate", newVal)
    }
  },
};
</script>

<style>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 0.5px solid #dcd8d8;
}
</style>