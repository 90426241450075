<template>
  <div>
    <v-form v-model="formValid">
      <v-subheader class="font-italic font-weight-light py-1"
        >Dati Generali</v-subheader
      >
      <v-row no-gutters>
        <v-col cols="2">
          <v-text-field
            v-model="codeOrder"
            filled
            disabled
            label="Codice Ordine"
            prepend-icon="mdi-code-tags"
            hide-details="auto"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            class="ml-3"
            v-model="descOrder"
            :placeholder="descPlaceHolder"
            filled
            :rules="[presenceRule]"
            label="Descrizione Ordine"
            prepend-icon="mdi-clipboard-text"
            hide-details="auto"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <DateTimePicker
            class="ml-2"
            v-model="expectedDeliveryDate"
            dense
            filled
            label="Data di consegna prevista"
            :show-time="false"
            date-picker-width="100%"
            :min="(() => {
              let today = new Date()
              return new Date(today.setDate(today.getDate() + 1))
            })()"
          ></DateTimePicker>
        </v-col>
        <v-col cols="3">
          <SuppliersAutocomplete
            class="ml-2"
            :external-search="supplierFromVoice"
            :rules="[presenceRule]"
            v-model="supplierSelected"
            label="Inserire Fornitore"
            return-object
            @input="handleSupplierChange"
            prepend-icon="mdi-domain"
            :append-outer-icon="undefined"
            :auto-detect-new-supplier="false"
          ></SuppliersAutocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="reason"
            label="Note"
            filled
            hide-details="auto"
            prepend-icon="mdi-comment"
            rows="2"
            row-height="15"
            :rules="[max255CharRule]"
            rounded
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <v-subheader class="font-italic font-weight-light mt-5"
      >Dettaglio ordine</v-subheader
    >
    <div
      class="border-default"
      style="
        height: 45vh;
        overflow-x: auto;
      "
      ref="dataTableWrapper"
    >
      <ItemDataTable
        :single-select="false"
        v-model="selectedItems"
        @input="handleSelection"
        class="mt-10"
        :loading="loading"
        :items="itemsAvailable"
        :headers="headerItems"
        :select-on-row-click="true"
        :show-actions="false"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
        :translator="translator"
        :row-per-page-options="[5000]"
        no-data-text="Selezionare fornitore per procedere"
      >
        <template v-slot:custom-pricePurchase="{ item }">
          <v-chip color="primary">
            € {{ item["pricePurchase"] ? item["pricePurchase"] : 0 }}</v-chip
          >
        </template>
        <template v-slot:custom-discountedPrice="{ item }">
          <v-chip color="primary">
            €
            {{
              item["pricePurchase"] && item["discount"]
                ? Number(
                    Number(item["pricePurchase"]) *
                      (1 - Number(item["discount"]) / 100)
                  ).toFixed(2)
                : Number(item["pricePurchase"]).toFixed(2)
            }}</v-chip
          >
        </template>
        <template v-slot:custom-discount="{ item }">
          <v-text-field
            class="border-default mt-3 mb-3"
            type="number"
            min="0"
            max="100"
            dense
            :rules="[]"
            @click.stop=""
            filled
            flat
            solo
            hide-details="auto"
            not-specified-text="0"
            v-model="item['discount']"
            @input="calcDiscount(item)"
          >
          </v-text-field>
        </template>
        <template v-slot:custom-priceTot="{ item }">
          <v-chip color="primary">
            €
            {{
              item["pricePurchase"] && item["discount"] && item["qtyTidying"]
                ? Number(
                    Number(item["pricePurchase"]) *
                      (1 - Number(item["discount"]) / 100) *
                      item["qtyTidying"]
                  ).toFixed(2)
                : Number(item["pricePurchase"] * item["qtyTidying"]).toFixed(2)
            }}</v-chip
          >
        </template>
        <template v-slot:custom-qtyTidying="{ item }">
          <v-text-field
            class="border-default mt-3 mb-3"
            type="number"
            min="0"
            max="100"
            dense
            @click.stop=""
            :rules="[qtyCheck]"
            filled
            flat
            solo
            hide-details="auto"
            not-specified-text="0"
            v-model="item['qtyTidying']"
          >
          </v-text-field>
        </template>
        <template v-slot:custom-qtyMinStock="{ item }">
          <v-chip color="orange">
            {{ item["qtyMinStock"] ? item["qtyMinStock"] : 0 }}</v-chip
          >
        </template>

        <template v-slot:custom-qtyInStock="{ item }">
          <v-chip v-if="item['qtyInStock'] > 0" color="success">
            {{ item["qtyInStock"] }}</v-chip
          >
          <v-chip v-else color="error">
            {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
          >
        </template>
      </ItemDataTable>
    </div>

    <v-row>
      <v-spacer></v-spacer>
      <OrderSummary :selected-items="selectedItems"></OrderSummary>
    </v-row>
  </div>
</template>

<script>
import GenericElementListDialog from "@/components/common/GenericElementListDialog.vue";

import Vue from "vue";
import itemService from "@/services/warehouse/items.service.js";
import ItemDataTable from "@/components/common/TypeDataTable.vue";
import currentUser from "@/services/currentUser.service";
import suppliersService from "@/services/suppliers/suppliers.service.js";
import ordersService from "@/services/warehouse/orders.service.js";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import BorderedDiv from "@/components/common/BorderedDivComponent.vue";
import OrderSummary from "@/components/areas/warehouse/orders/components/OrderSummary.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import DateTimePicker from '@/components/common/DateTimePicker.vue'

export default {
  components: {
    ItemDataTable,
    BorderedDiv,
    OrderSummary,
    GenericElementListDialog,
    SuppliersAutocomplete,
    DateTimePicker
  },
  data: function () {
    return {
      codeOrder: "",
      codePlaceHolder: "ES...OR01",
      descOrder: "Ordine",
      descPlaceHolder: "ES...Prodotti Salone, Prodotti Bar",
      loading: false,
      formValid: false,
      itemsAvailable: [],
      selectedItems: [],
      itemsNotAlreadyPresent: [],
      page: 1,
      rowPerPage: 5000,
      totalPages: 100,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      reason: undefined,
      supplierSelected: undefined,
      expectedDeliveryDate: undefined,
      suppliers: [],
      suppliersObject: [],
      headerItems: [
        {
          text: "Codice Interno",
          value: "internalCode",
        },
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Reparto",
          value: "department",
          type: "object",
          style: "chip",
        },
        {
          text: "Famiglia",
          value: "itemType",
          type: "object",
          style: "chip",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Quantità in Giacenza",
          value: "qtyInStock",
          type: "custom",
        },
        {
          text: "Scorta minima",
          value: "qtyMinStock",
          type: "custom",
        },
        {
          text: "Prezzo listino d'acquisto",
          value: "pricePurchase",
          type: "custom",
        },

        {
          text: "% di Sconto",
          value: "discount",
          type: "custom",
          width: "150px"
        },
        {
          text: "Prezzo unitario scontato",
          value: "discountedPrice",
          type: "custom",
        },
        {
          text: "N° pezzi da ordinare",
          value: "qtyTidying",
          type: "custom",
          width: "150px"
        },
        {
          text: "Totale Scontato",
          value: "priceTot",
          type: "custom",
        },
        { text: "", value: "actions", sortable: false },
      ],
      qtyCheck: (qty) => {
        if (qty <= 0) {
          this.isValid();
          return itemVocabulary.Correct_Qty.desc;
        } else {
          this.isValid();
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      max8CharRule: (v) => {
        if (v) {
          return v.length <= 8 || "Al massimo 8 caratteri";
        } else {
          return true;
        }
      },
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
    };
  },
  mounted() {
    this.setValidValue(false);
    this.lastCode();
    this.$store.dispatch("warehouse/orders/resetFields");

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);

    this.updateField('description', this.descOrder)
    this.isValid()

    this.setUser();
    this.fetchSuppliers();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  computed: {
    selectedItemsCheck() {
      let checkItems = [];
      this.selectedItems.map((item) => {
        if (item.qtyTidying && item.qtyTidying > 0) {
          checkItems.push(item);
        }
      });
      this.updateField("currentItemsSelected", checkItems);
      if (
        checkItems.length == this.selectedItems.length &&
        checkItems.length > 0
      )
        return true;
      else return false;
    },
  },
  methods: {
    calcDiscount(item) {
      if (item.discount >= 0) {
        item.priceNetPurchase = item.pricePurchase * (1 - item.discount / 100);
      } else item.discount = 0;
    },
    lastCode() {
      ordersService.getLastCode().then((lastOrder) => {
        let progressiveInCode;
        let today = new Date();
        if (
          !!lastOrder &&
          Number(lastOrder.startDate.substring(0, 4)) == today.getFullYear()
        ) {
          progressiveInCode = (Number(lastOrder.progressive) + 1).toString();
          while (progressiveInCode.length < 5) {
            progressiveInCode = "0" + progressiveInCode;
          }
        } else {
          progressiveInCode = "00001";
        }
        this.codeOrder = "ORD" + today.getFullYear() + "-" + progressiveInCode;
        this.updateField("code", this.codeOrder);
      });
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      } else if (field == "department" && value) {
        return value.name;
      }
    },
    handleAddGenericItem(genericElement) {
      let idx = this.itemsNotAlreadyPresent.length;
      this.itemsNotAlreadyPresent.push({
        id: idx,
        description: genericElement.description,
        pricePurchase: genericElement.price,
        discount: genericElement.discount,
        qtyTidying: genericElement.quantity,
      });
    },
    handleSupplierChange(supplierSelected) {
      this.bus.$emit("change-suppplier", supplierSelected);
    },
    handleSelection(itemsSelected) {
      this.selectedItems = itemsSelected;
    },
    setUser() {
      let userId = 0;
      currentUser.getUser().then((user) => {
        userId = user.id;
        this.updateField("userId", userId);
      });
    },
    fetchItems(filters) {
      this.loading = true;

      itemService.list(this.page, this.rowPerPage, filters).then((results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }
        this.itemsAvailable = results.rows;
        this.loading = false;
      });
    },
    fetchSuppliers() {
      return new Promise(async (resolve, reject) => {
        suppliersService
          .list()
          .then((results) => {
            this.suppliers = results.rows;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateField(nameField, value) {
      this.$store.dispatch("warehouse/orders/updateField", {
        field: nameField,
        value: value,
      });
    },
    setValidValue(value) {
      this.$emit("update:valid", value);
      if (this.bus) {
        this.bus.$emit("update:valid", value);
      }
      this.$store.dispatch("warehouse/orders/setValidState", value);
      this.formValid = value;
    },
    isValid() {
      if (
        this.selectedItemsCheck &&
        this.codeOrder !== "" &&
        this.descOrder !== ""
      ) {
        this.setValidValue(true);
      } else {
        this.setValidValue(false);
      }
    },
    resetFields() {
      this.$store.dispatch("warehouse/orders/resetFields");
      this.selectedItems = [];
      this.itemsAvailable = [];
      this.descOrder = "";
      this.reason = "";
      this.lastCode();
    },
  },
  props: {
    fromSubStock: {
      type: Boolean,
      default: false,
    },
    supplierFromVoice: {
      type: String,
      default: undefined
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  watch: {
    selectedItems(newVal) {
      this.selectedItems = newVal;
      this.isValid();
    },
    codeOrder(newVal) {
      this.updateField("code", newVal);
      this.isValid();
    },
    descOrder(newVal) {
      this.updateField("description", newVal);
      this.isValid();
    },
    expectedDeliveryDate(newVal) {
      this.updateField("expectedDeliveryDate", newVal);
    },
    reason(newVal) {
      this.updateField("reason", newVal);
      this.isValid();
    },
    supplierSelected(newVal, oldVal) {
      if (!!oldVal && !!newVal && newVal.id != oldVal.id) this.resetFields();
      if (newVal) {
        this.$store.dispatch("warehouse/orders/updateField", {
          field: "businessName",
          value: newVal.businessName,
        });
        this.$store.dispatch("warehouse/orders/updateField", {
          field: "supplierId",
          value: this.onEditing ? newVal : newVal.id,
        });
        this.onEditing = false;
        this.fetchItems([
          { type: 'custom', operator: 'custom', field: 'supplier', value: newVal.id },
          { type: 'custom', operator: 'custom', field: 'subStock', value: this.fromSubStock },
        ]);
      } else {
        this.resetFields();
      }
    },
    page() {
      this.fetchItems([
          { type: 'custom', operator: 'custom', field: 'supplier', value: this.supplierSelected.id },
          { type: 'custom', operator: 'custom', field: 'subStock', value: this.fromSubStock },
        ]);
    },
    rowPerPage() {
      this.fetchItems([
          { type: 'custom', operator: 'custom', field: 'supplier', value: this.supplierSelected.id },
          { type: 'custom', operator: 'custom', field: 'subStock', value: this.fromSubStock },
        ]);
    },
  },
};
</script>

<style>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #dcd8d8;
}
</style>